import React from "react"
import Wave from "./wave"
import { Link } from "gatsby"
import Logo from "./logo"

const Footer = () => {
  return (
    <section
      className="relative bg-indigo-700 pb-10 text-indigo-100"
      style={{
        backgroundColor: "#434190",
        backgroundImage: "linear-gradient(180deg, #434190 25%, #4c51bf 100%)",
      }}
    >
      <Wave className="text-white invert-wave" />
      <div className="container mx-auto px-5 flex flex-col md:flex-row items-end justify-between pt-12">
        <div className="flex-1 text-sm w-full">
          <div className="w-8 mb-5">
            <Logo wings="#A3BFFA" middle="#C3DAFE" bottom="#7F9CF5" />
          </div>
          <div className="text-indigo-200">
            <p>&copy; All Rights Reserved. {new Date().getFullYear()}</p>
            <p className="text-xs mt-2">
              Sendtinel is a registered product of{" "}
              <a href="https://90miles.agency" className="font-bold">
                90 Miles Agency
              </a>
            </p>
          </div>
          <span
            className="block bg-indigo-400 w-6/12 my-3"
            style={{ height: 1 }}
          ></span>
          <ul className="flex font-bold text-indigo-300">
            <li className="mr-5">
              <a href="#facebook">Facebook</a>
            </li>
            <li className="mr-5">
              <a href="#twitter">Twitter</a>
            </li>
            <li className="mr-5">
              <a href="#instagram">Instagram</a>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-auto mt-4 md:mt-0 text-indigo-200">
          <ul className="flex text-sm">
            <li className="md:ml-4">
              <Link to="/" activeClassName="text-indigo-400">
                Home
              </Link>
            </li>
            <li className="ml-4">
              <Link to="/features" activeClassName="text-indigo-400">
                Features
              </Link>
            </li>
            <li className="ml-4">
              <Link to="/contact" activeClassName="text-indigo-400">
                Contact
              </Link>
            </li>
            <li className="ml-4">
              <Link to="/privacy" activeClassName="text-indigo-400">
                Privacy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Footer
