import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "src/components/header"
import Footer from "src/components/footer"
import "../base.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      takeshape {
        getHomePage {
          heroSection {
            callToAction {
              label
              url
            }
          }
        }
      }
    }
  `)
  let { url, label } = data.takeshape.getHomePage.heroSection.callToAction
  return (
    <div className="text-gray-700 text-lg leading-relaxed">
      <Header url={url} label={label} />
      <div className="relative">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
