import React from "react"

const Logo = ({
  wings = "#4C51BF",
  middle = "#A3BFFA",
  bottom = "#434190",
}) => {
  return (
    <svg viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.9999 0L9.99207 13.5821L11.2677 8.56716L26.9999 0Z"
        fill={bottom}
      />
      <path
        d="M20.0906 14L27.0001 0L11.2678 8.56716L20.0906 14Z"
        fill={wings}
      />
      <path d="M0 5.22388L7.65352 6.89552L26.9999 0L0 5.22388Z" fill={wings} />
      <path
        d="M9.99214 13.5821L11.2677 8.56716L27 0L7.65356 6.89552L9.99214 13.5821Z"
        fill={middle}
      />
    </svg>
  )
}

export default Logo
