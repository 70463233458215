import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"

let activeLink = "text-gray-500 line-through"

let styledGradient = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  backgroundColor: "transparent",
  backgroundImage: "linear-gradient(0deg, transparent 0%, #CBD5E0 100%)",
  zIndex: "0",
}

const Header = ({ url = "", label = "" }) => {
  return (
    <section className="relative mb-12">
      <div className="h-56" style={styledGradient}></div>
      <nav className="container mx-auto pt-3 px-5 relative flex items-center justify-between">
        <div className="flex flex-col sm:flex-row mx-auto sm:mx-0">
          <Link
            to="/"
            className="flex font-bold text-3xl justify-center sm:justify-start text-indigo-700 items-center"
          >
            <span className="w-10 mr-2">
              <Logo />
            </span>
            Sendtinel.
          </Link>
          <ul className="flex text-gray-700 items-center sm:ml-10 mt-3 sm:mt-0">
            <li className="mr-4 sm:ml-4">
              <Link to="/" activeClassName={activeLink}>
                Home
              </Link>
            </li>
            <li className="mx-4">
              <Link to="/features" activeClassName={activeLink}>
                Features
              </Link>
            </li>
            <li className="mx-4">
              <Link to="/contact" activeClassName={activeLink}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <span className="hidden md:block">
          <a href={url} className="link">
            {label}
          </a>
        </span>
      </nav>
    </section>
  )
}

export default Header
